import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TemplateTiles } from "Pages/Promotions/CreatePromotion/utils/templateTiles";
import { PromotionEditor } from "Pages/Promotions/CreatePromotion/utils/promotionEditor";
import { setTemplateId } from "Redux/App/dataSlice";

const CreatePromotion = () => {
  const dispatch = useDispatch();
  const templateId = useSelector((state) => state.promotions.selectedTemplateId);
  useEffect(() => {
    return () => {
      dispatch(setTemplateId(null));
    };
  }, [dispatch]);

  if (templateId) {
    return <PromotionEditor templateId={templateId} temp />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        justifyContent: "start",
        alignItems: "start",
        height: "100%",
        width: "100%"
      }}>
      <TemplateTiles />
    </div>
  );
};

export default CreatePromotion;
