import axios from "axios";
import { notification } from "antd";
import { requestStart, requestCompleted } from "../..";
import { setLogsData, setLogsTotal } from "Redux/App/Reducers/Logs";

export function GetLogsData(page = 1, pageSize = 20) {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/logs?page=${page}&page_size=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );

      if (response.data) {
        dispatch(setLogsData(response.data));
        dispatch(setLogsTotal(response.data.total_count || 0));
        dispatch(requestCompleted());
      }
    } catch (error) {
      notification.error({
        message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
      });
      dispatch(requestCompleted());
    }
  };
}

export function GetSortedLogData(column, order) {
  return async (dispatch) => {
    dispatch(requestStart());
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/logs/sort/${column}?order=${order}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.data) {
        dispatch(setLogsData(response.data));
        dispatch(setLogsTotal(response.data.total_count || 0));

        dispatch(requestCompleted());
      }
    } catch (error) {
      notification.error({
        message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
      });
      dispatch(requestCompleted());
    }
  };
}

export function SetLogsArchiveStatus(data, setLoading, setSelectedLogs) {
  return async (dispatch, getState) => {
    const stateData = getState();
    const logs = stateData.logs.data; 

    dispatch(requestStart());
    setLoading(true);

    try {
      const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/logs/archive`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.data) {
        if (logs && logs.items) {
          const updatedArray = logs.items.map((originalRecord) => {
            const matchingRecord = response.data.find((newRecord) => newRecord.id === originalRecord.id);
            return matchingRecord ? matchingRecord : originalRecord;
          });

          dispatch(
            setLogsData({
              ...logs,
              items: updatedArray
            })
          );
        }
        dispatch(requestCompleted());
        setLoading(false);
        setSelectedLogs([]);
        notification.success({
          message: "Successfully updated the record"
        });
      }
    } catch (error) {
      notification.error({
        message: error?.response?.data?.detail ? error?.response?.data?.detail : "Something went wrong"
      });
      dispatch(requestCompleted());
      setLoading(false);
    }
  };
}
