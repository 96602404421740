import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const doGet = async (endpoint, { params = {}, ...headers } = {}) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios({
      method: "get",
      url: endpoint,
      baseURL,
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers
      }
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const doPost = async (endpoint, data, headers = {}) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios({
      method: "post",
      url: endpoint,
      baseURL,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers
      }
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const doPut = async (endpoint, data, options = {}) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios({
      method: "put",
      url: endpoint,
      baseURL,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        ...options.headers
      },
      params: options.params 
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const doPatch = async (endpoint, data, headers = {}) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios({
      method: "patch",
      url: endpoint,
      baseURL,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers
      }
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const doDelete = async (endpoint, { data, headers = {}, ...options } = {}) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios({
      method: "delete",
      url: endpoint,
      baseURL,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers
      },
      ...options
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};
