import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Table, Button, Input, notification } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import AddTagDrawer from "./AddCompanyTagsDrawer";
import { getTags, deleteTags } from "Requests/companies-tags";
import { getCompanies } from "Requests/audio-library";
import { TableWrapper } from "Styles/global";
import NewPromotionIcon from "Assets/Icons/NewPromotionIcon";

const TagManagementPage = ({}) => {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [companiesLoading, setCompaniesLoading] = useState(false);
  const companyId = searchParams.get("company_id") ? Number(searchParams.get("company_id")) : null;

  useEffect(() => {
    fetchTags();
    if (!companyId) {
      fetchCompaniesList();
    }
  }, [companyId]);

  const fetchTags = async () => {
    setLoading(true);
    try {
      const data = await getTags(companyId);
      setTags(data);
    } catch (error) {
      notification.error({ message: "Failed to fetch tags." });
    } finally {
      setLoading(false);
    }
  };
  const fetchCompaniesList = async () => {
    setCompaniesLoading(true);
    try {
      const comps = await getCompanies();
      setCompanies(comps);
    } catch (error) {
      notification.error({ message: "Failed to fetch companies." });
    } finally {
      setCompaniesLoading(false);
    }
  };

  const handleAddOrUpdateTag = (newTag) => {
    setTags((prevTags) => {
      const existingIndex = prevTags.findIndex((item) => item.id === newTag.id);
      if (existingIndex > -1) {
        const updated = [...prevTags];
        updated[existingIndex] = newTag;
        return updated;
      }
      return [newTag, ...prevTags];
    });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}>
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => {
      const recordValue = record[dataIndex];
      return recordValue?.toString().toLowerCase().includes(value.toLowerCase()) || false;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ""} />
      ) : (
        text
      )
  });

  const handleDeleteSelected = async () => {
    if (selectedRowKeys.length === 0) return;
    try {
      await deleteTags(selectedRowKeys);
      notification.success({ message: "Tags deleted successfully." });
      setTags((prev) => prev.filter((tag) => !selectedRowKeys.includes(tag.id)));
      setSelectedRowKeys([]);
    } catch (error) {
      notification.error({ message: "Failed to delete tags." });
    }
  };

  const columns = [
    {
      title: "",
      dataIndex: "checkbox",
      render: (_, record) => (
        <input
          type="checkbox"
          checked={selectedRowKeys.includes(record.id)}
          onChange={(e) => {
            const checked = e.target.checked;
            setSelectedRowKeys((prev) => (checked ? [...prev, record.id] : prev.filter((id) => id !== record.id)));
          }}
        />
      ),
      width: 50
    },
    {
      title: "Tag",
      dataIndex: "tag_name",
      key: "tag_name",
      ...getColumnSearchProps("tag_name"),
      render: (text, record) => (
        <span
          style={{ color: "#39B54A", cursor: "pointer" }}
          onClick={() => {
            setSelectedTag(record);
            setIsDrawerVisible(true);
          }}>
          {text}
        </span>
      )
    },
    {
      title: "Description",
      dataIndex: "tag_description",
      key: "tag_description",
      ...getColumnSearchProps("tag_description")
    }
  ];

  return (
    <TableWrapper>
      {!isDrawerVisible && (
        <div
          className="new-promotion"
          onClick={() => {
            setSelectedTag(null);
            setIsDrawerVisible(true);
          }}
          style={{ marginBottom: "10px", cursor: "pointer", display: "inline-block" }}>
          <NewPromotionIcon />
        </div>
      )}
      {selectedRowKeys.length > 0 && (
        <Button type="danger" onClick={handleDeleteSelected} style={{ marginLeft: "10px", marginBottom: "10px" }}>
          Delete Selected
        </Button>
      )}
      <Table
        dataSource={tags}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          defaultPageSize: 10
        }}
      />

      <AddTagDrawer
        visible={isDrawerVisible}
        onClose={() => {
          setIsDrawerVisible(false);
          setSelectedTag(null);
        }}
        tagData={selectedTag}
        handleAddOrUpdateTag={handleAddOrUpdateTag}
        companyOptions={companyId ? null : companies}
        companyId={companyId}
      />
    </TableWrapper>
  );
};

export default TagManagementPage;
