/* eslint-disable no-lone-blocks */
import React, { useRef, useState, useEffect } from "react";
import { FilterFilled } from "@ant-design/icons";
import { Button, Input, Space, Table, Row, Col } from "antd";
import { TableWrapper } from "Styles/global";
import { CampaignsTable } from "./styles";
import { useDispatch } from "react-redux";
import { GetCampaigns } from "Redux/App/Actions/Campaigns";
import { SortCampaign } from "Redux/App/Actions/Campaigns";
import NewPromotionIcon from "Assets/Icons/NewPromotionIcon";
import CampaignDrawer from "../Promotions/CampaignDetail/CampaignDetail";
import DrawerComponent from "Components/Shared/Drawer";
import { useNavigate } from "react-router-dom";
import { userProfileData, companyName, selectedCompaign, setCompany } from "Redux/App";
import { useSelector } from "react-redux";
import AdminCreateCampaign from "./AdminCampaign";

const Campaigns = () => {
  const userData = useSelector(userProfileData);
  const dispatch = useDispatch();
  const selectedCompany = useSelector(companyName);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState({
    CampaignDetailDrawer: false,
    editCampaignName: ""
  });
  const [createCampaignDrawer, setCreateCampaignDrawer] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const userRole = JSON.parse(localStorage.getItem("userInfo"));
  const [campaigns, setCampaigns] = useState({});
  const [campaignsData, setCampaignsData] = useState({
    loading: false,
    data: []
  });
  const selected = useSelector(selectedCompaign);
  useEffect(() => {
    dispatch(setCompany(selected));
    if (selectedCompany) dispatch(GetCampaigns(campaignsData, setCampaignsData));
  }, [selectedCompany]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8
        }}>
        <Row gutter={8}>
          <Col span="14">
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                display: "block"
              }}
            />
          </Col>
          <Col span="5">
            <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}>
              Search
            </Button>
          </Col>
          <Col span="5">
            <Button
              onClick={() => clearFilters && handleReset(clearFilters, confirm)}
              type="primary"
              style={{
                marginLeft: "5px",
                background: "#FFFFFF",
                color: " #9B9B9B",
                border: "1px solid #d9d9d9"
              }}>
              Clear
            </Button>
          </Col>
        </Row>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled
        style={{
          color: filtered ? "#1890ff" : undefined
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (text ? text.toString() : "") : text)
  });

  const columns = [
    {
      title: "Status",
      dataIndex: "status"
    },
    {
      title: "Campaign Name",
      dataIndex: "campaign_name",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...getColumnSearchProps("campaign_name"),
      render: (text, item) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            {
              if (userData?.permission_level === "Fmtv") {
                navigate("/campaign-edit", {
                  state: { campaign: item.id }
                });
              } else {
                setCampaigns(item);
                if (
                  userData?.permission_level === "Admin" ||
                  userData?.permission_level === "Superuser" ||
                  userData?.permission_level === "Publisher" ||
                  userData?.permission_level === "Creator"
                ) {
                  setDrawerOpen({
                    ...drawerOpen,
                    CampaignDetailDrawer: true,
                    editCampaignName: item.id
                  });
                }
              }
            }
          }}>
          {item?.campaign_name}
        </div>
      )
    },
    {
      title: "Description",
      dataIndex: "current_description",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...getColumnSearchProps("current_description")
    },
    {
      title: "Screen Type",
      dataIndex: "screen_type"
    },
    {
      title: "Orientation",
      dataIndex: "orientation",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...getColumnSearchProps("orientation"),
      render: (orientation) => orientation || "Landscape"
    }
  ];

  return (
    <TableWrapper>
      <CampaignsTable>
        <Table
          dataSource={campaignsData.data}
          columns={columns}
          loading={campaignsData.loading}
          pagination={{ showSizeChanger: true, pageSizeOptions: ["10", "20", "50", "100"], defaultPageSize: 10 }}
          onChange={(a, b, c) => {
            let order = "";

            if (c.order === "ascend") {
              order = "asc";
            } else if (c.order) {
              order = "desc";
            }
            if (c.field) dispatch(SortCampaign(c.field, order, setCampaignsData, campaignsData, selectedCompany));
          }}
        />
        {!createCampaignDrawer && (
          <div
            className="new-promotion"
            onClick={() => {
              if (userData?.permission_level === "Admin" || userData?.permission_level === "Superuser") {
                setCreateCampaignDrawer(true);
              } else {
                navigate("/campaign-setup");
              }
            }}>
            <NewPromotionIcon />
          </div>
        )}
      </CampaignsTable>

      <DrawerComponent placement="right" setDrawerOpen={setCreateCampaignDrawer} drawerOpen={createCampaignDrawer}>
        <AdminCreateCampaign
          setDrawerOpen={setCreateCampaignDrawer}
          drawerOpen={createCampaignDrawer}
          campaign={campaigns}
          campaignsData={campaignsData}
          setCampaignsData={setCampaignsData}
        />
      </DrawerComponent>

      <DrawerComponent placement="right" setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen.CampaignDetailDrawer}>
        <CampaignDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} campaign={campaigns} />
      </DrawerComponent>
    </TableWrapper>
  );
};

export default Campaigns;
