import { Image } from "antd";
import { createCloudFrontUrl } from "utils/createCloudFrontURL";

const RenderMedia = ({ promoVideo }) => {
  if (promoVideo) {
    const finalSrc = promoVideo?.template_url?.includes(".s3.amazonaws.com")
      ? createCloudFrontUrl(promoVideo.template_url)
      : promoVideo.template_url;

    return <Image preview={false} width="100%" src={finalSrc} />;
  }
  return null;
};

export default RenderMedia;