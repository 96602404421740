import React, { useState, useEffect, useRef } from "react";
import { Table, Modal, Row, Col, Input, Button, Checkbox } from "antd";
import { FilterFilled } from "@ant-design/icons";
import MouseSquare from "Assets/Icons/MouseSquare";
import { ModalBtn } from "../Promotions/PromotionModal/styles";
import { TableWrapper, PageSubTitleBlack } from "Styles/global";
import { GetLogsData, GetSortedLogData } from "Redux/App/Actions/Logs";
import { useDispatch, useSelector } from "react-redux";
import { loading as stateLoading } from "Redux/App";
import { logsData as stateData, logsTotal as totalLogsCount } from "Redux/App/Reducers/Logs";
import { formatDate } from "./settings.utls";
import StatusModal from "./LogsModal";
import FileNameModal from "Components/Shared/FileNameModal/FileNameModal";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import { ExportButton } from "./styles";

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

const Logs = () => {
  const dispatch = useDispatch();
  const data = useSelector(stateData); 
  const loading = useSelector(stateLoading);
  const totalRecords = useSelector(totalLogsCount); 
  const [formattedData, setFormattedData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const searchInput = useRef(null);
  const [modalContent, setModalContent] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [archived, setArchivedActive] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [exportedData, setExportedData] = useState(null);

  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const [fileName, setFileName] = useState(`Logs_${moment().format("YYYYMMDD_HHmmss")}`);

  const exportToCSV = (csvData, fileName) => {
    const myHeader = ["Created At", "Event", "Level", "Description", "Details", "Error", "Archived At"];
    const fileExtension = ".xlsx";

    const finalData = csvData.map((item) => ({
      "Created At": item.created_at,
      Event: item.event,
      Level: item.level,
      Description: item.description,
      Details: item.details,
      Error: item.error,
      "Archived At": item.archived_at
    }));

    const ws = XLSX.utils.json_to_sheet(finalData, { header: myHeader });
    const wb = { Sheets: { Logs: ws }, SheetNames: ["Logs"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const e_data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(e_data, fileName + fileExtension);
    setFileName(`Logs_${moment().format("YYYYMMDD_HHmmss")}`);
    setOpenFileModal(false);
  };

  const clickExport = () => setOpenFileModal(true);

  const handleExport = () => {
    const finalData = exportedData || formattedData;
    exportToCSV(finalData, fileName ?? "logs");
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Row gutter={8}>
          <Col span="14">
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ display: "block" }}
            />
          </Col>
          <Col span="5">
            <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}>
              Search
            </Button>
          </Col>
          <Col span="5">
            <Button
              onClick={() => clearFilters && handleReset(clearFilters, confirm)}
              type="primary"
              style={{
                marginLeft: "5px",
                background: "#FFFFFF",
                color: " #9B9B9B",
                border: "1px solid #d9d9d9"
              }}>
              Clear
            </Button>
          </Col>
        </Row>
      </div>
    ),
    filterIcon: (filtered) => <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => {
      if (typeof record[dataIndex] === "object") {
        return Object.values(record[dataIndex]).some((val) =>
          val?.toString()?.toLowerCase()?.includes(value.toLowerCase())
        );
      } else {
        return record[dataIndex]?.toString()?.toLowerCase()?.includes(value.toLowerCase());
      }
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (text ? text.toString() : "") : text)
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleSortChange = (pagination, filters, sorter, extra) => {
    setPageNumber(pagination.current);
    const column = sorter.field;
    const order = sorter.order === "descend" ? "desc" : "asc";
    setExportedData(extra.currentDataSource);

    if (column) {
      dispatch(GetSortedLogData(column, order));
    }
  };

  const handleCellClick = (text) => {
    setModalContent(text);
    setIsModalVisible(true);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setIsRowSelected(newSelectedRowKeys.length > 0);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  useEffect(() => {
    dispatch(GetLogsData(pageNumber, limit));
  }, [dispatch, pageNumber, limit]);

  useEffect(() => {
    setSelectedRowKeys([]);
    if (data.items && data.items.length > 0) {
      let dataD = data.items.map((item) => ({
        ...item,
        key: item.id,
        created_at: formatDate(item.created_at),
        archived_at: item.archived_at ? item.archived_at : "N/A"
      }));

      if (!archived) {
        dataD = dataD.filter((value) => value?.status !== "Archived");
      }
      setFormattedData(dataD);
    } else {
      setFormattedData([]);
    }
  }, [data, archived]);

  const renderTwoLines = (text) => {
    const style = {
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      height: "3em",
      textOverflow: "ellipsis",
      cursor: "pointer"
    };
    return <div style={style}>{text}</div>;
  };

  const columns = [
    {
      title: "Created At",
      dataIndex: "created_at",
      width: 150,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...getColumnSearchProps("created_at")
    },
    {
      title: "Event",
      dataIndex: "event",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...getColumnSearchProps("event")
    },
    {
      title: "Level",
      dataIndex: "level",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...getColumnSearchProps("level")
    },
    {
      title: "Description",
      dataIndex: "description",
      width: 300,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...getColumnSearchProps("description"),
      render: (text) => <div onClick={() => handleCellClick(text)}>{renderTwoLines(text)}</div>
    },
    {
      title: "Details",
      dataIndex: "details",
      width: 200,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...getColumnSearchProps("details"),
      render: (text) => <div onClick={() => handleCellClick(text)}>{renderTwoLines(text)}</div>
    },
    {
      title: "Error",
      dataIndex: "error",
      width: 200,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...getColumnSearchProps("error"),
      render: (text) => <div onClick={() => handleCellClick(text)}>{renderTwoLines(text)}</div>
    },
    {
      title: "Archived At",
      dataIndex: "archived_at",
      width: 150,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...getColumnSearchProps("archived_at")
    }
  ];

  const handlePaginationChange = (page, pageSize) => {
    setPageNumber(page);
    setLimit(pageSize);
  };

  return (
    <TableWrapper>
      <StatusModal
        isModalOpen={showStatusModal}
        setModalOpen={setShowStatusModal}
        selectedLogs={selectedRowKeys}
        setSelectedLogs={onSelectChange}
      />

      <FileNameModal
        fileName={fileName}
        setFileName={setFileName}
        open={openFileModal}
        handleOk={handleExport}
        handleCancel={() => setOpenFileModal(false)}
      />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <PageSubTitleBlack>Logs</PageSubTitleBlack>
        <div>
          {!isRowSelected && (
            <Checkbox
              checked={archived}
              onChange={() => {
                setArchivedActive(!archived);
              }}>
              Archived
            </Checkbox>
          )}
          {isRowSelected && (
            <ModalBtn onClick={() => setShowStatusModal(true)}>
              <MouseSquare />
            </ModalBtn>
          )}
          <div>
            <ExportButton onClick={clickExport}>Export Table</ExportButton>
          </div>
        </div>
      </div>

      <Table
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange
        }}
        loading={loading || !Array.isArray(formattedData)}
        dataSource={Array.isArray(formattedData) ? formattedData : []}
        columns={columns}
        onChange={handleSortChange}
        pagination={{
          current: pageNumber,
          pageSize: limit,
          total: totalRecords,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100", "150", "200"],
          position: ["bottomCenter"],
          showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} logs`,
          showQuickJumper: true
        }}
        scroll={{ x: "max-content" }}
      />

      <Modal
        title="Full Content"
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        width={800}>
        <div style={{ overflow: "auto" }}>{modalContent}</div>
      </Modal>
    </TableWrapper>
  );
};

export default Logs;
