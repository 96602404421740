import { doGet, doPost, doPut, doDelete } from "Requests/requests";

/**
 * @param {number|null} companyId
 * @returns {Promise<Object[]>}
 */
export const getTags = async (companyId = null) => {
  try {
    const params = {
      offset: 0,
      limit: 100,
      ...(companyId && { company_id: companyId })
    };
    return await doGet(`/company-tag`, { params });
  } catch (e) {
    throw e;
  }
};

/**
 * Creates a new tag.
 * @param {Object} data { tag_name, tag_description, status, company_id }
 * @returns {Promise<Object>}
 */
export const createTag = async (data) => {
  try {
    return await doPost(`/company-tag`, data);
  } catch (e) {
    throw e;
  }
};

/**
 * Updates an existing tag.
 * @param {number} id
 * @param {Object} data { tag_name, tag_description, status, company_id }
 * @returns {Promise<Object>}
 */
export const updateMasterTag = async (id, data) => {
  return await doPut(`/master-tag/${id}`, data);
};


/**
 * Deletes multiple tags by their IDs.
 * @param {number[]} ids
 * @returns {Promise<void>}
 */
export const deleteTags = async (ids) => {
  try {
    return await doDelete(`/company-tag/bulk`, { data: { ids } });
  } catch (e) {
    throw e;
  }
};

/**
 * Fetch master tags with optional search text, offset, and limit.
 * @param {string} searchTag
 * @param {number} offset
 * @param {number} limit
 * @returns {Promise<Array>}
 */
export const getMasterTags = async (searchTag = "", offset = 0, limit = 100) => {
  try {
    const params = {
      search_tag: searchTag,
      offset,
      limit
    };
    return await doGet("/master-tag", { params });
  } catch (error) {
    throw error;
  }
};

/**
 * Create a new master tag.
 * @param {Object} data { tag_id, tag_name, tag_description, status }
 * @returns {Promise<Object>}
 */
export const createMasterTag = async (data) => {
  try {
    return await doPost("/master-tag", data);
  } catch (error) {
    throw error;
  }
};
