import { createSlice } from "@reduxjs/toolkit";

const logsInitialState = {
  data: {}, 
  total: 0 
};

const logsSlice = createSlice({
  name: "logs",
  initialState: logsInitialState,
  reducers: {
    setLogsData(state, { payload }) {
      state.data = payload; 
    },
    setLogsTotal(state, { payload }) {
      state.total = payload; 
    }
  }
});

export const { setLogsData, setLogsTotal } = logsSlice.actions;

export const logsData = (state) => state.logs.data;
export const logsTotal = (state) => state.logs.total;

export const logsReducer = logsSlice.reducer;
