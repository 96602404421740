import React, { useEffect, useState, useRef, useCallback } from "react";
import { Select, Row, Col, DatePicker, InputNumber, Button, Form, Upload, notification, Modal, Tooltip } from "antd";
import { UploadPromotionWrapper } from "./styles";
import RemoveIcon from "Assets/Icons/RemoveIcon";
import Calendar from "Assets/Icons/Calendar";
import {
  ActionButton,
  DrawerTitle,
  MainTitle,
  DrawerFormHeading,
  DrawerBodyPadding,
  CloseBtnWrapper
} from "Styles/global";
import UploadIcon from "Assets/Icons/UploadIcon";
import CloseBtnIcon from "Assets/Icons/CloseBtn";
import { UploadPromotion as UploadPromotionAction } from "Redux/App/Actions/Promos";
import { GetCampaigns } from "Redux/App/Actions/Campaigns";
import { useDispatch, useSelector } from "react-redux";
import { userProfileData, companyName } from "Redux/App";
import { getCompanies, getCompanyCoupon } from "Redux/App/Actions/Companies";
import LoadingSpinner from "Components/Shared/LoadingSpinner";
import moment from "moment";
import { GetSystemSettings } from "Redux/App/Actions/System";
import GroupIcon from "Assets/Icons/GroupIcon";
import CouponFalse from "Assets/Icons/CouponFalse";
import { systemSettingsData as stateData, systemSettingsData } from "Redux/App/Reducers/System";
import { SUPPORTED_VIDEO_RES } from "constants/Templates";

export const UploadPromotion = (props) => {
  let uploadProps = {};
  const { couponOptions, setCouponOptions, formValues, setFormValues } = props;
  const [campaigns, setCampaigns] = useState({ data: [], loading: false });
  const [startDate, setStartDate] = useState();
  const [uploadPromotionLoading, setUploadPromotionLoading] = useState(false);
  const [effectiveDateNotValid, setEffectiveDateNotValid] = useState(false);
  const [endDateNotValid, setEndDateNotValid] = useState(false);
  const userData = useSelector(userProfileData);
  const selectedCompany = useSelector(companyName);
  const [form] = Form.useForm();
  const [promotionOrientation, setPromotionOrientation] = useState("landscape");
  const [resolution, setResolution] = useState("");

  const [file, setFile] = useState({
    selectedFile: null,
    fileAsText: ""
  });
  const [isCouponOption, setCoupon] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isImgAndDurationIsLessThan5, setIsImgAndDurationIsLessThan5] = useState(false);
  const isSingleSelect = useSelector(systemSettingsData)?.limit_promo_campaign;
  const fileSizeLimit = useSelector(systemSettingsData)?.promo_file_size;

  const dispatch = useDispatch();
  const { Option } = Select;
  const { Dragger } = Upload;
  const [companies, setCompanies] = useState({
    data: [],
    loading: false
  });

  useEffect(() => {
    if (!props.drawerOpen.AddCouponDrawer) {
      form.setFieldsValue(formValues);
      if (formValues.selectedFile) {
        uploadProps.defaultFileList = [formValues?.selectedFile];
        setFile({
          selectedFile: formValues.selectedFile,
          fileAsText: formValues.fileAsText
        });
      }
    }
  }, [props.drawerOpen.AddCouponDrawer]);

  useEffect(() => {
    if (userData?.permission_level === "Fmtv") {
      dispatch(getCompanies(companies, setCompanies));
    }
    dispatch(GetSystemSettings());
    dispatch(GetCampaigns(campaigns, setCampaigns));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setCoupon(await getCompanyCoupon(selectedCompany));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    form.setFieldsValue({
      company_id: userData?.permission_level === "Fmtv" ? selectedCompany : userData?.company_name
    });
  }, [selectedCompany, userData?.company_name, userData?.permission_level]);

  const onFinish = (values) => {
    if (!isVideo && values.duration < 5) {
      setIsImgAndDurationIsLessThan5(true);
      return;
    }

    if (values?.campaign_id) {
      if (!Array.isArray(values.campaign_id)) {
        values.campaign_id = [values.campaign_id];
      }

      const selectedCampaigns = campaigns.data.filter((c) => values.campaign_id.includes(c.id));

      selectedCampaigns.forEach((c, index) => {});

      const orientationMismatch = selectedCampaigns.some((c) => c.orientation !== promotionOrientation);
      if (orientationMismatch) {
        notification.error({
          message: "Orientation Mismatch",
          description:
            "The orientation of the promotion doesn't match the orientation of the campaign. Please clear or adjust the campaign setting and resubmit."
        });
        return;
      }
    }

    if (values?.effective_date && values?.end_date) {
      values.effective_date = moment(values.effective_date).format("YYYY-MM-DD");
      values.end_date = moment(values.end_date).format("YYYY-MM-DD");

      const effDate = moment(values.effective_date);
      const endDate = moment(values.end_date);
      const currentDate = moment(new Date());

      if (effDate.isBefore(currentDate) && endDate.isAfter(currentDate)) {
        values.status = "Active";
      } else {
        values.status = "Pending";
      }

      if (effDate.isBefore(currentDate) && values.status !== "Active") {
        setEffectiveDateNotValid(true);
        return;
      }
      if (endDate.isBefore(effDate) || endDate.isBefore(currentDate)) {
        setEndDateNotValid(true);
      }
    } else {
      values.status = "Pending";
    }

    if (!file.selectedFile) {
      notification.error({
        message: "Please upload a valid file"
      });
      return;
    }

    if (userData?.permission_level !== "Fmtv") {
      values.company_id = userData?.company;
    }

    values.coupon = true;
    values.thumbnail = "testing";
    values.promotion_name = file.selectedFile.name;
    values.date_updated = moment(new Date()).format("YYYY-MM-DD");
    values.file_url = file.fileAsText.split("base64,")[1];
    const inputString = file.fileAsText.split("base64,")[0];
    const parts = inputString.split(":");
    values.media_type = parts[1].slice(0, -1);
    values.resolution = resolution;
    values.orientation = promotionOrientation;

    if (values.hasOwnProperty("file_url")) {
      dispatch(
        UploadPromotionAction(values, handleCancel, setUploadPromotionLoading, couponOptions, () => {
          props.refreshPromotions();
        })
      );
    }
  };

  const handleDurationModalOk = () => setIsImgAndDurationIsLessThan5(false);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    props.setDrawerOpen({
      ...props.drawerOpen,
      UploadPromotionDrawer: false
    });
    setFormValues({
      ...form.getFieldsValue(),
      selectedFile: file.selectedFile,
      fileAsText: file.fileAsText
    });
  };

  const [disableButton, setDisableButton] = useState();
  uploadProps = {
    multiple: false,
    accept: ".jpeg, .png, .mp4, .webm",
    fileList: formValues.selectedFile ? [formValues?.selectedFile] : file?.selectedFileList,
    maxCount: 1,
    onChange: (info) => {
      const nextState = {};
      switch (info.file.status) {
        case "uploading":
          nextState.selectedFileList = [info.file];
          break;
        case "done":
          nextState.selectedFile = info.file;
          nextState.selectedFileList = [info.file];
          break;
        default:
          nextState.selectedFile = null;
          nextState.selectedFileList = [];
      }
    },
    onRemove: () => {
      setFile({ ...file, selectedFile: null, selectedFileList: [] });
      form.setFieldValue({ file_url: null });
      setDisableButton(false);
      setResolution("");

      return true;
    },
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    beforeUpload: (file) => {
      if (
        !(
          file.type === "video/mp4" ||
          file.type === "video/webm" ||
          file.type === "image/png" ||
          file.type === "image/jpeg"
        )
      ) {
        notification.error({
          message: "Uploaded file format not supported"
        });
        setDisableButton(true);
        return false;
      }
      setIsVideo(file.type.includes("video"));
      const videoElement = document.createElement("video");
      var fileInput = document.getElementById("video");

      videoElement.src = URL.createObjectURL(file);
      videoElement.addEventListener("loadedmetadata", () => {
        const videoWidth = videoElement.videoWidth;
        const videoHeight = videoElement.videoHeight;
        const videoDuration = videoElement.duration;
        if (file.type.includes("video")) {
          form.setFieldsValue({ duration: Math.round(videoDuration) });
        }

        const orientation = videoWidth >= videoHeight ? "landscape" : "portrait";
        setPromotionOrientation(orientation);
        const currentResolution = `${videoWidth}x${videoHeight}`;
        setResolution(currentResolution);
        if (!SUPPORTED_VIDEO_RES.includes(currentResolution)) {
          notification.error({
            message: `The promotion file is "${videoWidth}x${videoHeight}" and does not conform to one of the following resolutions. Please convert and re-upload.`,
            description: `1920x1080 (FHD 16:9), 1280x720 (HD 16:9), 1080x1920 (FHD 9:16), 720x1280 (HD 9:16), 1024x512, 512x640`
          });
          setDisableButton(true);
          if (fileInput) fileInput.value = "";
          return false;
        } else {
          setDisableButton(false);
        }
      });
      videoElement.load();

      const fileSizeInMB = file.size / 1024 ** 2;
      if (fileSizeInMB > fileSizeLimit) {
        notification.error({
          message: `Please upload file less than ${fileSizeLimit}MB.`
        });
        if (fileInput) fileInput.value = "";
        setDisableButton(true);
        setFile({ selectedFile: null, fileAsText: "" });
        return false;
      } else {
        setDisableButton(false);
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        if (file.type.includes("image")) {
          const image = new Image();
          image.src = e.target.result;
          image.onload = () => {
            const orientation = image.width >= image.height ? "landscape" : "portrait";
            setPromotionOrientation(orientation);
            setResolution(`${image.width}x${image.height}`);
            setFile({
              ...file,
              fileAsText: e.target.result,
              selectedFile: file
            });
            setDisableButton(false);
            return true;
          };
        } else {
          setFile({
            ...file,
            fileAsText: e.target.result,
            selectedFile: file
          });
          setDisableButton(false);
          return true;
        }
      };
      reader.readAsDataURL(file);
      return false;
    }
  };

  const handleOpenEditCouponDrawer = () => {
    props.setDrawerOpen({
      ...props.drawerOpen,
      UploadPromotionDrawer: false,
      AddCouponDrawer: true
    });
    setFormValues({
      ...form.getFieldsValue(),
      selectedFile: file.selectedFile,
      fileAsText: file.fileAsText
    });
  };

  return (
    <UploadPromotionWrapper>
      <DrawerTitle height={118}>
        <CloseBtnWrapper>
          <CloseBtnIcon onClick={handleCancel} className="close-icon-upload-promotion" />
        </CloseBtnWrapper>
        <MainTitle>
          <p className="main-title">Upload Promotion </p>
        </MainTitle>
      </DrawerTitle>
      <DrawerBodyPadding>
        <Form onFinish={onFinish} form={form} onFinishFailed={onFinishFailed}>
          <div className="mb-16">
            <DrawerFormHeading>Select campaign</DrawerFormHeading> <span>(optional)</span>
          </div>
          <Form.Item name="campaign_id">
            <Select
              loading={campaigns.loading}
              removeIcon={() => (
                <div style={{ marginTop: 2 }}>
                  <RemoveIcon />
                </div>
              )}
              mode={isSingleSelect ? "" : "multiple"}
              style={{
                width: "100%"
              }}
              placeholder="Please select campaigns">
              {campaigns.data
                .filter((campaign) => campaign.status !== "Archived" && campaign.status !== "Pending")
                .map((campaign) => (
                  <Option key={campaign?.id} value={campaign?.id}>
                    {campaign?.campaign_name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="company_id"
            rules={[
              {
                required: true,
                message: "Please select a company"
              }
            ]}
            style={userData?.permission_level !== "Fmtv" ? { display: "none" } : {}}>
            <Select
              loading={companies.loading}
              disabled={userData?.permission_level === "Fmtv" ? false : true}
              removeIcon={() => (
                <div style={{ marginTop: 2 }}>
                  <RemoveIcon />
                </div>
              )}
              style={{
                width: "100%"
              }}
              placeholder="Please select company"
              defaultValue={selectedCompany}>
              {companies.data.map((companyItem) => (
                <Option key={companyItem?.id} value={companyItem?.id}>
                  {companyItem?.company_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className="mb-16">
            <DrawerFormHeading>Upload the promotion files</DrawerFormHeading>{" "}
          </div>
          <Form.Item>
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <UploadIcon />
              </p>
              <p className="ant-upload-text">Click to upload the promotion files</p>
              <p className="ant-upload-hint"> {`Maximum file size ${fileSizeLimit} MB`}</p>
            </Dragger>
          </Form.Item>

          <div className="horizontal-line"></div>

          <Row gutter="24">
            <Col span="12">
              <DrawerFormHeading>
                <Tooltip title="Enter the date the promotion should start displaying based on your current local date/time">
                  <span>Effective date</span>
                </Tooltip>
              </DrawerFormHeading>
              <Form.Item name="effective_date">
                <DatePicker
                  id="1"
                  onChange={() => {
                    setStartDate(startDate);
                    form.setFieldValue("end_date", "");
                  }}
                  suffixIcon={<Calendar />}
                />
              </Form.Item>
            </Col>
            <Col span="12">
              <DrawerFormHeading>
                <Tooltip title="Enter the date the promotion should stop displaying based on your current local date/time">
                  <span>End date</span>
                </Tooltip>
              </DrawerFormHeading>
              <Form.Item
                name="end_date"
                rules={[
                  {
                    required: startDate ? true : false,
                    message: "Please select the end date"
                  }
                ]}>
                <DatePicker id="2" suffixIcon={<Calendar />} disabledDate={(d) => !d || d.isBefore(startDate)} />
              </Form.Item>
            </Col>
            <Col span="12">
              <DrawerFormHeading style={{ marginRight: "16px" }}>Duration</DrawerFormHeading>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Form.Item name="duration">
                  <InputNumber disabled={isVideo} min={0} />
                </Form.Item>
                <Col span="12">
                  {isCouponOption && (
                    <div className="edit-coupon-wrapper">
                      <span className="edit-coupon-heading">Coupon</span>
                      {couponOptions.coupon && (
                        <span onClick={handleOpenEditCouponDrawer} className="edit-coupon">
                          Edit
                        </span>
                      )}
                      {userData?.permission_level !== "Publisher" && (
                        <div className="coupon-icon" style={{ marginLeft: "16px" }}>
                          {couponOptions.coupon ? (
                            <GroupIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => setCouponOptions({ ...couponOptions, coupon: false })}
                            />
                          ) : (
                            <CouponFalse
                              style={{ cursor: "pointer" }}
                              onClick={() => setCouponOptions({ ...couponOptions, coupon: true })}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </Col>
              </div>
            </Col>
          </Row>

          {uploadPromotionLoading && <LoadingSpinner />}
          <Modal open={isImgAndDurationIsLessThan5} onCancel={handleDurationModalOk} footer={null} title={null}>
            Duration must be 5 seconds or greater.
          </Modal>
          <Modal
            title=""
            open={effectiveDateNotValid}
            footer={null}
            onOk={() => setEffectiveDateNotValid(false)}
            onCancel={() => setEffectiveDateNotValid(false)}>
            The Effective Date should be equal to or greater than today's date
          </Modal>

          <Modal
            title=""
            open={endDateNotValid}
            onOk={() => setEndDateNotValid(false)}
            onCancel={() => setEndDateNotValid(false)}>
            The End Date must be equal to or greater than today's date and also must be equal to or greater than the
            Effective Date
          </Modal>
          <Row className="action-buttons ">
            <Col span="12">
              <ActionButton background="##FFFFFF;" color=" #9B9B9B" width="150">
                <Button onClick={handleCancel}>Cancel</Button>
              </ActionButton>
            </Col>
            <Col span="12">
              <ActionButton background="#39B54A" color="#FFFFFF" width="150">
                <Button htmlType="submit" disabled={disableButton}>
                  Upload
                </Button>
              </ActionButton>
            </Col>
          </Row>
        </Form>
      </DrawerBodyPadding>
    </UploadPromotionWrapper>
  );
};
