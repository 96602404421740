import React, { useEffect, useState, useCallback } from "react";
import { Drawer, Form, Input, Select, Button, Row, Col, notification, AutoComplete } from "antd";
import { DrawerTitle, MainTitle, CloseBtnWrapper, DrawerBodyPadding } from "Styles/global";
import CloseBtnIcon from "Assets/Icons/CloseBtn";
import { createTag, updateMasterTag, getMasterTags, createMasterTag } from "Requests/companies-tags";

const { TextArea } = Input;

const AddTagDrawer = ({ visible, onClose, tagData, handleAddOrUpdateTag, companyOptions, companyId }) => {
  const [form] = Form.useForm();

  const [allTags, setAllTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [isAddingNewTag, setIsAddingNewTag] = useState(false);

  const [typedTagName, setTypedTagName] = useState("");

  const fetchAllTags = useCallback(async () => {
    try {
      const data = await getMasterTags("", 0, 1000);
      if (Array.isArray(data)) {
        setAllTags(data);
      } else if (Array.isArray(data?.data)) {
        setAllTags(data.data);
      } else {
        setAllTags([]);
      }
    } catch (error) {
      console.error("Error fetching all master tags:", error);
      setAllTags([]);
    }
  }, []);

  const onFocusTagName = () => {
    if (allTags.length === 0) {
      fetchAllTags();
    }
  };

  const onSearchTagName = (searchText) => {
    setTypedTagName(searchText);
  };

  const onSelectTagName = (value, option) => {
    if (option?.key === "addNewTag") {
      setIsAddingNewTag(true);
      form.setFieldsValue({ tag_description: "" });
    } else {
      setIsAddingNewTag(false);
      if (option?.item) {
        form.setFieldsValue({
          tag_description: option.item.tag_description || ""
        });
      }
    }
  };

  useEffect(() => {
    const lowerTyped = typedTagName.toLowerCase().trim();
    const partialMatches = allTags.filter((tag) => tag.tag_name.toLowerCase().includes(lowerTyped));

    setFilteredTags(partialMatches);
  }, [typedTagName, allTags]);

  const lowerTyped = typedTagName.toLowerCase();
  const existingTagNames = filteredTags.map((t) => t.tag_name.toLowerCase());

  const options = filteredTags.map((tag) => ({
    value: tag.tag_name,
    label: tag.tag_name,
    key: `tag_${tag.id}`,
    item: tag
  }));

  if (typedTagName && !existingTagNames.includes(lowerTyped)) {
    options.push({
      value: typedTagName,
      label: `Add New Tag: "${typedTagName}"`,
      key: "addNewTag"
    });
  }

  useEffect(() => {
    if (tagData) {
      form.setFieldsValue({
        tag_name: tagData.tag_name,
        tag_description: tagData.tag_description,
        company_id: tagData.company_id || companyId || null,
        status: tagData.status
      });
      setTypedTagName(tagData.tag_name || "");
      setIsAddingNewTag(false);
    } else {
      form.resetFields();
      if (companyId) {
        form.setFieldsValue({ company_id: companyId });
      }
      form.setFieldsValue({ status: "pending" });
      setTypedTagName("");
      setIsAddingNewTag(false);
    }
  }, [tagData, companyId, form]);

  const onFinish = async (values) => {
    try {
      if (!values.company_id) {
        notification.error({
          message: "Please select or provide a company."
        });
        return;
      }

      let tag_id = values.tag_id || 0;
      let status = values.status;

      if (isAddingNewTag) {
        const newMasterTagPayload = {
          tag_id: 0,
          tag_name: values.tag_name,
          tag_description: values.tag_description || "",
          status: "pending"
        };
        const newMasterTag = await createMasterTag(newMasterTagPayload);
        tag_id = newMasterTag.id;
        status = "pending";
      }

      const { status: _, ...createTagValues } = values;
      createTagValues.tag_id = tag_id;
      createTagValues.status = status;

      if (tagData) {
        const updated = await updateMasterTag(tagData.id, createTagValues);
        notification.success({ message: "Tag updated successfully." });
        if (handleAddOrUpdateTag) handleAddOrUpdateTag(updated);
      } else {
        const created = await createTag(createTagValues);
        notification.success({ message: "Tag created successfully." });
        if (handleAddOrUpdateTag) handleAddOrUpdateTag(created);
      }

      onClose();
    } catch (error) {
      console.error("Error =>", error);
      notification.error({
        message: "An error occurred while processing the tag."
      });
    }
  };

  return (
    <Drawer
      headerStyle={{ display: "none" }}
      placement="right"
      closable={false}
      onClose={onClose}
      open={visible}
      width={400}>
      <DrawerTitle height={118}>
        <CloseBtnWrapper onClick={onClose}>
          <CloseBtnIcon />
        </CloseBtnWrapper>
        <MainTitle>
          <p className="main-title">{tagData ? "Edit Tag" : "Add New Tag"}</p>
        </MainTitle>
      </DrawerTitle>

      <DrawerBodyPadding>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            name="tag_name"
            label="Tag Name"
            rules={[
              { required: true, message: "Please enter tag name" },
              { max: 15, message: "Maximum 15 characters allowed" }
            ]}>
            <AutoComplete
              placeholder="Enter tag name"
              onFocus={onFocusTagName}
              onSearch={onSearchTagName}
              onSelect={onSelectTagName}
              options={options}
              allowClear
              filterOption={false}
            />
          </Form.Item>

          <Form.Item name="tag_description" label="Description">
            <TextArea
              placeholder="Enter tag description"
              rows={3}
              disabled={!isAddingNewTag && !!tagData === false && existingTagNames.includes(lowerTyped)}
            />
          </Form.Item>

          <Form.Item name="status" label="Status" rules={[{ required: true, message: "Please select status" }]}>
            <Select placeholder="Select status" disabled={!tagData}>
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="pending">Pending</Select.Option>
            </Select>
          </Form.Item>

          {companyId && !companyOptions && (
            <Form.Item name="company_id" hidden>
              <Input type="hidden" />
            </Form.Item>
          )}

          {!companyId && companyOptions && (
            <Form.Item
              name="company_id"
              label="Company"
              rules={[{ required: true, message: "Please select a company" }]}>
              <Select placeholder="Select company" allowClear>
                {companyOptions.map((company) => (
                  <Select.Option key={company.id} value={company.id}>
                    {company.company_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Row gutter={16} justify="end">
            <Col>
              <Button onClick={onClose}>Cancel</Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </DrawerBodyPadding>
    </Drawer>
  );
};

export default AddTagDrawer;
