import React, { useEffect, useState } from "react";
import { Row, Col, DatePicker, InputNumber, Button, Form, Table, Input, Spin, Tooltip, Modal, Tag } from "antd";
import { EditPromotionWrapper, PromotionName } from "./styles";
import Calendar from "Assets/Icons/Calendar";
import GroupIcon from "Assets/Icons/GroupIcon";
import CloseBtnIcon from "Assets/Icons/CloseBtn";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import {
  ActionButton,
  DrawerTitle,
  MainTitle,
  DrawerFormHeading,
  TableWrapper,
  ExtraContent,
  DrawerBodyPadding,
  CloseBtnWrapper,
  SpinStyling
} from "Styles/global";
import { GetCampaigns } from "Redux/App/Actions/Campaigns";
import { EditPromotion as EditPromtionAction, GetSinglePromos, UpdateCoupan } from "Redux/App/Actions/Promos/";
import { copyPromotionToTemplate } from "Redux/App/Actions/Promos";
import LoadingSpinner from "Components/Shared/LoadingSpinner";
import CouponFalse from "Assets/Icons/CouponFalse";
import { userProfileData } from "Redux/App";
import { systemSettingsData } from "Redux/App/Reducers/System";
import { useNavigate } from "react-router-dom";
import { setTemplateId } from "Redux/App/dataSlice";

export const EditPromotion = (props) => {
  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";
  const [campaigns, setCampaigns] = useState({ data: [], loading: false });
  const [promotionData, setPromotionData] = useState({
    data: {},
    loading: false,
    keys: []
  });
  const [editPromotionLoading, setEditPromotionLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState(promotionData.keys);
  const [promotionDate, setPromotionDate] = useState({
    effectiveDate: moment(promotionData.data?.effective_date).format(dateFormat),
    endDate: moment(promotionData.data?.end_date).format(dateFormat)
  });
  const [promoStatus, setPromoStatus] = useState("");
  const userData = useSelector(userProfileData);
  const [effectiveDateNotValid, setEffectiveDateNotValid] = useState(false);
  const [endDateNotValid, setEndDateNotValid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isImgAndDurationIsLessThan5, setIsImgAndDurationIsLessThan5] = useState(false);
  const isSingleSelect = useSelector(systemSettingsData)?.limit_promo_campaign;

  const handleOk = () => {
    setPromoStatus("Paused");
    setIsModalOpen(false);
    form.submit();
  };

  const handleCancelModel = () => {
    setIsModalOpen(false);
    handleCancel();
  };

  useEffect(() => {
    if (props?.drawerOpen?.editPromotionData?.id) {
      dispatch(
        GetSinglePromos(props?.drawerOpen?.editPromotionData?.id, promotionData, setPromotionData, setSelectedRowKeys)
      );
    }
    dispatch(GetCampaigns(campaigns, setCampaigns));
  }, []);

  const filteredCampaigns = campaigns?.data?.filter(
    (item) =>
      item.status !== "Archived" &&
      item.status !== "Pending" &&
      (!promotionData.data.orientation || item.orientation === promotionData.data.orientation)
  );

  useEffect(() => {
    if (promotionData.data.media_type) {
      setIsVideo(promotionData.data.media_type.includes("video"));
    }
  }, [promotionData]);

  useEffect(() => {
    form.setFieldsValue({
      effective_date: promotionData.data?.effective_date
        ? moment(promotionData.data?.effective_date, dateFormat)
        : moment(Date.now()),
      end_date: promotionData.data?.end_date ? moment(promotionData.data?.end_date, dateFormat) : moment(Date.now()),
      duration: promotionData.data?.duration,
      updated_name: promotionData.data?.promotion_name
    });
    setPromotionDate({
      effectiveDate: promotionData.data?.effective_date
        ? moment(promotionData.data?.effective_date, dateFormat)
        : moment(Date.now()),
      endDate: promotionData.data?.end_date ? moment(promotionData.data?.end_date, dateFormat) : moment(Date.now())
    });
  }, [form, promotionData]);

  useEffect(() => {
    onSelectChange(promotionData?.keys?.map((item) => parseInt(item)));
  }, [promotionData, promotionData.keys]);

  const onSelectChange = (newSelectedRowKeys) => {
    if (newSelectedRowKeys) setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    type: isSingleSelect ? "radio" : "check",
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: props.editpromotion ? true : record.status === "Archived",
      status: record.status
    })
  };

  const handleCancel = () => {
    props.setDrawerOpen({
      ...props.drawerOpen,
      EditPromotionDrawer: false
    });
  };

  const columns = [
    {
      title: "Campaign",
      dataIndex: "campaign_name",
      key: "campaign_name"
    },
    {
      title: "Screen Type",
      dataIndex: "screen_type"
    },
    {
      title: "Status",
      dataIndex: "status"
    }
  ];

  const onFinish = (values) => {
    if (values.duration < 5 && !isVideo) {
      setIsImgAndDurationIsLessThan5(true);
      return;
    }
    setIsModalOpen(false);
    values.campaign_id = selectedRowKeys;
    values.promotion_name = props?.drawerOpen?.editPromotionData?.promotion_name;
    values.status = promoStatus;
    values.promo_id = props?.drawerOpen?.editPromotionData?.id;
    if (promotionData.data?.promotion_name === values.updated_name) {
      values.updated_name = "";
    }
    if (values?.effective_date && values?.end_date) {
      values.effective_date = moment(promotionDate.effectiveDate).format("YYYY-MM-DD");
      values.end_date = moment(promotionDate.endDate).format("YYYY-MM-DD");
      let effDate = moment.tz(values.effective_date, "YYYY-MM-DD", "UTC");
      let endDate = moment.tz(values.end_date, "YYYY-MM-DD", "UTC");
      let currentDate = moment.tz(new Date().toString(), "YYYY-MM-DD", "UTC");
      if (effDate.isBefore(currentDate)) {
        setEffectiveDateNotValid(true);
        return;
      }
      if (endDate.isBefore(effDate) || endDate.isBefore(currentDate)) {
        setEndDateNotValid(true);
        return;
      }
    }
    dispatch(EditPromtionAction(values, handleCancel, setEditPromotionLoading, props.screen, props.refreshPromotions));
  };

  const handleDurationModalOk = () => setIsImgAndDurationIsLessThan5(false);

  const handleModifyPromotionClick = async () => {
    dispatch(setTemplateId(props.drawerOpen.editPromotionData?.id));
    navigate("/create-promotion");
  };

  const isMp4Video = promotionData.data.media_type?.toLowerCase().includes("mp4");
  const isDimensionSupported =
    promotionData.data.resolution === "1920x1080" || promotionData.data.resolution === "1280x720"; 
  const isPortrait = promotionData.data.orientation === "portrait";
  const isPromotionEditable = userData?.create_promo_flag && isMp4Video && (isDimensionSupported || isPortrait);

  return (
    <Form onFinish={onFinish} form={form}>
      <EditPromotionWrapper>
        <DrawerTitle height={118}>
          <CloseBtnWrapper>
            <CloseBtnIcon onClick={handleCancel} className="close-icon-upload-promotion" />
          </CloseBtnWrapper>
          <MainTitle>
            <p className="main-title">Edit Promotion</p>
          </MainTitle>
        </DrawerTitle>
        <DrawerBodyPadding>
          {promotionData.loading ? (
            <SpinStyling>
              <Spin />
            </SpinStyling>
          ) : (
            <>
              <DrawerFormHeading>Promotion name</DrawerFormHeading>
              <PromotionName>
                <Form.Item
                  name="updated_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter promotion name...!"
                    }
                  ]}>
                  <Input disabled={props.editpromotion ? true : false} />
                </Form.Item>
                <Tag
                  color="#39B54A"
                  style={{
                    borderRadius: 40,
                    margin: 5,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: 13,
                    display: isPromotionEditable ? "inline-block" : "none",
                    textDecoration: isPromotionEditable ? "underline" : "none",
                    cursor: isPromotionEditable ? "pointer" : "default"
                  }}
                  onClick={isPromotionEditable ? handleModifyPromotionClick : undefined}>
                  Modify Promotion
                </Tag>
              </PromotionName>
              <DrawerFormHeading>Select Campaign(s)</DrawerFormHeading>
              <ExtraContent>Select the Campaign(s) associated with this promotion</ExtraContent>
              <TableWrapper>
                <div className="table-height">
                  <Table
                    columns={columns}
                    dataSource={filteredCampaigns}
                    pagination={false}
                    loading={campaigns.loading}
                    rowSelection={rowSelection}
                  />
                </div>
              </TableWrapper>
              <Row gutters="24">
                <Col span="12">
                  <DrawerFormHeading>
                    <Tooltip title="Enter the date the promotion should start displaying based on your current local date/time">
                      <span>Effective date</span>
                    </Tooltip>
                  </DrawerFormHeading>
                  <Form.Item name="effective_date">
                    <DatePicker
                      disabled={props.editpromotion || promotionData?.data?.status === "Active" ? true : false}
                      disabledDate={(d) => {
                        if (promotionData?.data?.status !== "Active") {
                          return !d || d.isBefore(moment(new Date()).format("YYYY-MM-DD"));
                        } else {
                          return null;
                        }
                      }}
                      format={dateFormat}
                      suffixIcon={<Calendar />}
                      allowClear={false}
                      onChange={(x, y) => {
                        setPromotionDate({
                          ...promotionDate,
                          effectiveDate: y
                        });
                        if (promotionDate?.end_date && moment(promotionDate.end_date).isBefore(x)) {
                          form.setFieldValue("end_date", "");
                        } else {
                          form.setFieldsValue({
                            end_date: promotionData.data?.end_date
                              ? moment(promotionData.data?.end_date, dateFormat)
                              : moment(Date.now())
                          });
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span="12">
                  <DrawerFormHeading>
                    <Tooltip title="Enter the date the promotion should stop displaying based on your current local date/time">
                      <span>End date</span>
                    </Tooltip>
                  </DrawerFormHeading>
                  <Form.Item
                    rules={[
                      {
                        required: promotionDate.effectiveDate ? true : false,
                        message: "Please select the end date"
                      }
                    ]}
                    name="end_date">
                    <DatePicker
                      format={dateFormat}
                      allowClear={false}
                      suffixIcon={<Calendar />}
                      disabledDate={(d) =>
                        !d || d.isBefore(promotionDate.effectiveDate) || props.editpromotion ? true : false
                      }
                      disabled={props.editpromotion ? true : false}
                      onChange={(d, y) =>
                        setPromotionDate({
                          ...promotionDate,
                          endDate: y
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span="12">
                  <DrawerFormHeading>Duration</DrawerFormHeading>
                  <div>
                    <Form.Item name="duration">
                      <InputNumber
                        disabled={Boolean(props.editpromotion) || isVideo || promotionData.data?.vistar_creative_id}
                        min={0}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span="12">
                  {promotionData?.data?.company_coupon && (
                    <div className="edit-coupon-wrapper">
                      <DrawerFormHeading>Coupon</DrawerFormHeading>
                      {promotionData?.data?.coupon && (
                        <span
                          onClick={() =>
                            props.setDrawerOpen({
                              ...props.drawerOpen,
                              EditPromotionDrawer: false,
                              EditCouponDrawer: true
                            })
                          }
                          className="edit-coupon">
                          Edit
                        </span>
                      )}
                      {userData?.permission_level !== "Publisher" && (
                        <div className="coupon-icon">
                          {promotionData?.data?.coupon ? (
                            <GroupIcon
                              style={{ cursor: "pointer" }}
                              onClick={async () => {
                                await dispatch(
                                  UpdateCoupan(
                                    "Edit coupon button",
                                    {
                                      coupon_details: {},
                                      promo_id: props?.drawerOpen.editPromotionData?.id,
                                      coupon: false
                                    },
                                    setEditPromotionLoading,
                                    {},
                                    setPromotionData
                                  )
                                );
                              }}
                            />
                          ) : (
                            <CouponFalse
                              style={{ cursor: "pointer" }}
                              onClick={async () => {
                                await dispatch(
                                  UpdateCoupan(
                                    "Edit coupon button",
                                    {
                                      coupon_details: {},
                                      promo_id: props?.drawerOpen.editPromotionData?.id,
                                      coupon: true
                                    },
                                    setEditPromotionLoading,
                                    {},
                                    setPromotionData
                                  )
                                );
                                props.setDrawerOpen({
                                  ...props.drawerOpen,
                                  EditPromotionDrawer: false,
                                  EditCouponDrawer: true
                                });
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </Col>
                <Col span={24}>
                  <DrawerFormHeading>Current Status</DrawerFormHeading>
                  <div>
                    <Form.Item>
                      <Input disabled value={props?.drawerOpen.editPromotionData?.status} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              {editPromotionLoading && <LoadingSpinner />}
              <Modal title="Confirm" open={isModalOpen} onOk={handleOk} onCancel={handleCancelModel}>
                <p>
                  This Promotion is currently in an Active or Upcoming state. This change will Deactivate the Promotion
                  and the Promotion will need to be re-Published in order for the requested changes to be applied.
                </p>
              </Modal>
              <Modal open={isImgAndDurationIsLessThan5} onCancel={handleDurationModalOk} footer={null} title={null}>
                Duration must be 5 seconds or greater.
              </Modal>
              <Modal
                title=""
                open={effectiveDateNotValid}
                footer={null}
                onOk={() => setEffectiveDateNotValid(false)}
                onCancel={() => setEffectiveDateNotValid(false)}>
                The Effective Date should be equal to or greater than today's date
              </Modal>
              <Modal
                title=""
                open={endDateNotValid}
                onOk={() => setEndDateNotValid(false)}
                onCancel={() => setEndDateNotValid(false)}>
                The End Date must be equal to or greater than today's date and also must be equal to or greater than the
                Effective Date
              </Modal>
              <Row className="action-buttons ">
                <Col span="12">
                  <ActionButton background="#FFFFFF" color="#9B9B9B" width="150">
                    <Button disabled={props.editpromotion ? true : false} onClick={handleCancel}>
                      Cancel
                    </Button>
                  </ActionButton>
                </Col>
                {props.drawerOpen.editPromotionData.status !== "Archived" && (
                  <Col span="12">
                    <ActionButton background="#39B54A" color="#FFFFFF" width="150">
                      <Button
                        disabled={props.editpromotion ? true : false}
                        onClick={() => {
                          if (
                            props.drawerOpen.editPromotionData.status === "Active" ||
                            props.drawerOpen.editPromotionData.status === "Upcoming"
                          ) {
                            setIsModalOpen(true);
                          } else {
                            form.submit();
                          }
                        }}>
                        Submit
                      </Button>
                    </ActionButton>
                  </Col>
                )}
              </Row>
            </>
          )}
        </DrawerBodyPadding>
      </EditPromotionWrapper>
    </Form>
  );
};
