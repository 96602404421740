import React, { useEffect } from "react";
import { Form, Select } from "antd";

const Orientation = ({ screenType }) => {
  const { Option } = Select;

  const isPortraitAllowed = screenType === "In-Store";

  return (
    <Form.Item
      name="orientation"
      label="Orientation"
      rules={[
        {
          required: true,
          message: "Please select the orientation"
        }
      ]}>
      <Select placeholder="Select Orientation" defaultValue="Landscape">
        <Option value="Landscape">Landscape</Option>
        <Option value="Portrait" disabled={!isPortraitAllowed}>
          Portrait
        </Option>
      </Select>
    </Form.Item>
  );
};

export default Orientation;
